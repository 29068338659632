export default {
  name: "Запеченные роллы",
  anchor: "zapech-rolli",
  items: [
    {
      name: "Запечённый ролл Калифорния",
      img: "/images/menu/0V4A8683 .jpg",
      alt: "",
      desc: "рис, нори, крабовый микс, огурец, запеченный соус, масаго",
      price: 540,
      size: null,
    },
    {
      name: "Запеченный лосось",
      img: "/images/menu/0V4A8683 .jpg",
      alt: "",
      desc: "рис, нори, сыр, огурец, лосось, запеченный соус, масаго\n",
      price: 570,
      size: null,
    },
    {
      name: "Запеченный с креветкой",
      img: "/images/menu/0V4A8698 .jpg",
      alt: "",
      desc: "рис, нори, сыр, огурец, креветка, запеченный соус, кунжут\n",
      price: 580,
      size: null,
    },
    {
      name: "Запеченный микс",
      img: "/images/menu/0V4A8711 .jpg",
      alt: "",
      desc: "рис, нори, лосось, угорь, крабовый микс, соус для запекания, унаги-соус, кунжут\n",
      price: 560,
      size: null,
    },
    {
      name: "Ролл спешл",
      img: "/images/menu/0V4A8753 .jpg",
      alt: "",
      desc: "рис, нори, сыр, лук зеленый, помидор, запеченный соус",
      price: 510,
      size: null,
    },
    {
      name: "Запеченный император",
      img: "/images/menu/0V4A8761 .jpg",
      alt: "",
      desc: "рис, угорь, лосось, креветка, запеченный соус, унаги-соус",
      price: 630,
      size: null,
    },
    {
      name: "Запеченный от шефа",
      img: "/images/menu/c36a1109f6.jpeg",
      alt: "",
      desc: "рис, нори, сыр, имбирь, жареный лосось, унаги, кунжут",
      price: 555,
      size: null,
    },
    {
      name: "Запеченный угорь",
      img: "/images/menu/Рол запеченный лосось терияки _.jpg",
      alt: "",
      desc: "рис, нори, сыр, огурец, угорь, запеченный соус, унаги-соус\n",
      price: 669,
      size: null,
    },
    {
      name: "Запеченный лосось терияки",
      img: "/images/menu/Рол запеченный лосось терияки _.jpg",
      alt: "",
      desc: "рис, нори, сыр, жареный лосось, огурец, запеченный соус, терияки-соус",
      price: 550,
      size: null,
    },
    {
      name: "Запеченный курица и грибы",
      img: "/images/menu/123123.jpg",
      alt: "",
      desc: "рис, нори, курица, сливки, шампиньон, запеченный соус",
      price: 449,
      size: null,
    },
  ],
};
