import React from "react";
import { Link } from "react-scroll";

function Header() {
  const headerRef = React.useRef(null);
  const headerBgRef = React.useRef(null);

  function fixHeaderRow() {
    const header = headerRef.current;
    const headerBg = headerBgRef.current;

    if (window.scrollY > 20) {
      headerBg.classList.add("fixed-header");
      header.style.paddingTop = "210px";
      headerBg.style.background = "#393939";
      headerBg.style.height = "89px";
      if (document.documentElement.clientWidth < 1300) {
        headerBg.style.height = "65px";
        header.style.paddingTop = "180px";
      }
      if (document.documentElement.clientWidth < 768) {
        headerBg.style.height = "85px";
      }
    } else {
      headerBg.classList.remove("fixed-header");
      header.style.paddingTop = "20px";
      headerBg.style.background = "none";
      headerBg.style.height = "inherit";
    }
  }
  function closeBaner(event){
    document.querySelector('.baner-warning').classList.add('visual-hidden')
  }

  function handleButtonClick(event) {
    event.target.classList.toggle("menu__button--active");
  }

  React.useEffect(() => {
    window.addEventListener("scroll", fixHeaderRow);
    return () => window.removeEventListener("scroll", fixHeaderRow);
  }, []);

  return (
    <header ref={headerRef} className="header">
      <div ref={headerBgRef} className="header__row-bg-fixed">
        <div className="header__row container">
          <a href="#" className="header__logo-link">
            <img src="/images/logo.svg" alt="" className="header__logo-image"/>
          </a>
          <nav className="menu">
            <button className="menu__button" onClick={handleButtonClick}/>
            <ul className="menu__list">
              <a href="#" className="header__logo-link">
                <img src="/images/logo.svg" alt="" className="header__logo-image"/>
              </a>
              <li className="menu__item">
                <Link to="combo" smooth={true} offset={-50} className="menu__link">
                  Комбо и наборы
                </Link>
              </li>
              <li className="menu__item">
                <Link to="pizza" smooth={true} offset={-50} className="menu__link">
                  Пицца
                </Link>
              </li>
              <li className="menu__item">
                <Link to="rolli" smooth={true} offset={-50} className="menu__link">
                  Роллы и суши
                </Link>
              </li>
              <li className="menu__item">
                <Link to="zapech-rolli" smooth={true} offset={-50} className="menu__link">
                  Запеченные роллы
                </Link>
              </li>
              <li className="menu__item">
                <Link to="tempura" smooth={true} offset={-50} className="menu__link">
                  Темпура роллы
                </Link>
              </li>
              <li className="menu__item">
                <Link to="wok" smooth={true} offset={-50} className="menu__link">
                  WOK
                </Link>
              </li>
              <li className="menu__item">
                <Link to="hot" smooth={true} offset={-50} className="menu__link">
                  Горячие блюда
                </Link>
              </li>
              <li className="menu__item">
                <Link to="kalcone" smooth={true} offset={-50} className="menu__link">
                  Кальцоне и чебуреки
                </Link>
              </li>
              <li className="menu__item">
                <Link to="soup" smooth={true} offset={-50} className="menu__link">
                  Супы и салаты
                </Link>
              </li>
              <li className="menu__item">
                <Link to="garnir" smooth={true} offset={-50} className="menu__link">
                  Закуски
                </Link>
              </li>
              <li className="menu__item">
                <Link to="deserti" smooth={true} offset={-50} className="menu__link">
                  Напитки и десерты
                </Link>
              </li>
              <li className="menu__item dostavka">
                <Link to="dostavka" smooth={true} offset={-50} className="menu__link">
                  <p>
                    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                      <g id="Filled_outline" data-name="Filled outline">
                        <circle cx="50" cy="55" fill="#394d5c" r="6"></circle>
                        <circle cx="50" cy="55" fill="#cfdfe8" r="2"></circle>
                        <circle cx="22" cy="55" fill="#394d5c" r="6"></circle>
                        <circle cx="22" cy="55" fill="#cfdfe8" r="2"></circle>
                        <path d="m50 20h6v8h-6z" fill="#cb2e5c" transform="matrix(-1 0 0 -1 106 48)"></path>
                        <path d="m34 48h16v6h-16z" fill="#cb2e5c" transform="matrix(-1 0 0 -1 84 102)"></path>
                        <path d="m10 36h24a0 0 0 0 1 0 0v12a6 6 0 0 1 -6 6h-12a6 6 0 0 1 -6-6v-12a0 0 0 0 1 0 0z"
                              fill="#b11e48" transform="matrix(-1 0 0 -1 44 90)"></path>
                        <path d="m60 20h1a0 0 0 0 1 0 0v8a0 0 0 0 1 0 0h-1a4 4 0 0 1 -4-4 4 4 0 0 1 4-4z" fill="#fcf05a"
                              transform="matrix(-1 0 0 -1 117 48)"></path>
                        <path d="m14 41h16a0 0 0 0 1 0 0v11a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2-2v-11a0 0 0 0 1 0 0z"
                              fill="#cb2e5c" transform="matrix(-1 0 0 -1 44 95)"></path>
                        <path d="m6 42h4v4h-4z" fill="#e97424" transform="matrix(-1 0 0 -1 16 88)"></path>
                        <path d="m50 54h11l-5-26h-6z" fill="#b11e48"></path>
                        <path d="m4 50h6v4h-6z" fill="#e97424" transform="matrix(-1 0 0 -1 14 104)"></path>
                        <path d="m42 12h6v4h-6z" fill="#50b3c9"></path>
                        <path d="m23 32v4h15v8h4v-10a2 2 0 0 0 -2-2z" fill="#195c85"></path>
                        <path d="m38 44h5a2 2 0 0 1 2 2v2a0 0 0 0 1 0 0h-7a0 0 0 0 1 0 0v-4a0 0 0 0 1 0 0z"
                              fill="#394d5c"></path>
                        <path d="m23 18h10v14h-10z" fill="#55b56a"></path>
                        <path d="m34 24-7-1v4l7 1h8v-4z" fill="#8ec13f"></path>
                        <circle cx="46" cy="26" fill="#ffd782" r="2"></circle>
                        <path d="m33 18h-10v-7h5v-3h5z" fill="#ffd782"></path>
                        <path d="m26 3a3 3 0 0 0 -3 3v5h5v-3h5v-2a3 3 0 0 0 -3-3z" fill="#55b56a"></path>
                        <rect fill="#8ec13f" height="14" rx="2" width="14" x="3" y="22"></rect>
                        <path
                            d="m57 19h-6.382l-1.618-3.236v-3.764a1 1 0 0 0 -1-1h-6a1 1 0 0 0 -1 1v4a1 1 0 0 0 1 1h5.382l1.618 3.236v26.764h-3v-1a3 3 0 0 0 -3-3v-9a3 3 0 0 0 -3-3h-6v-2h8a1 1 0 0 0 1-1v-4a1 1 0 0 0 -1-1h-7.877l-.123-.031v-16.969a4 4 0 0 0 -4-4h-4a4 4 0 0 0 -4 4v29h-4.2a2.735 2.735 0 0 0 .2-1v-10a3.119 3.119 0 0 0 -3-3h-10a3.119 3.119 0 0 0 -3 3v10a3.119 3.119 0 0 0 3 3h6.111a6.975 6.975 0 0 0 -2.031 4h-3.08a1 1 0 0 0 -1 1v4a1 1 0 0 0 1 1h3v2h-5a1 1 0 0 0 -1 1v4a1 1 0 0 0 1 1h11a7 7 0 0 0 14 0h14a7 7 0 0 0 14 0h4a1 1 0 0 0 .982-1.189l-4.775-24.832a4.991 4.991 0 0 0 -.207-9.979zm-10-4h-4v-2h4zm-38 38h-4v-2h4zm0-8h-2v-2h2zm42-24h4v6h-4zm0 34a1 1 0 0 1 -2 0zm-8-10a1 1 0 0 1 1 1v1h-5v-2zm-9.242-20.03a.984.984 0 0 0 .242.03h7v2h-6.877l-6.123-.751v-1.938zm-9.758-18.97a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h-4a1 1 0 0 0 -1 1v2h-3zm0 6h4a1 1 0 0 0 1-1v-2h3v8h-8zm0 7h8v3.469l-4.758-.409a1 1 0 0 0 -1.242.97v4a1 1 0 0 0 .758.97l5.242.531v2.469h-8zm0 14h16a1 1 0 0 1 1 1v9h-2v-7a1 1 0 0 0 -1-1h-14zm-19 2a1.151 1.151 0 0 1 -1-1v-10a1.151 1.151 0 0 1 1-1h10a1.151 1.151 0 0 1 1 1v10a1.151 1.151 0 0 1 -1 1zm17 25a5.006 5.006 0 0 1 -5-5h2a3 3 0 0 0 6 0h2a5.006 5.006 0 0 1 -5 5zm-1-5h2a1 1 0 0 1 -2 0zm8-2h-14v-10a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm4 0h-2v-10a3 3 0 0 0 -3-3h-12a3 3 0 0 0 -3 3v10h-2v-11a5.006 5.006 0 0 1 5-5h12a5.006 5.006 0 0 1 5 5zm-.111-16h4.111v10h-2v-5a6.973 6.973 0 0 0 -2.111-5zm2.111 16v-4h14v4zm15 7a5.006 5.006 0 0 1 -5-5h2a3 3 0 0 0 6 0h2a5.006 5.006 0 0 1 -5 5zm9.789-7h-8.789v-24h4.174zm-2.789-26v-6a3 3 0 0 1 0 6z"></path>
                        <path d="m46 29a3 3 0 1 0 -3-3 3 3 0 0 0 3 3zm0-4a1 1 0 1 1 -1 1 1 1 0 0 1 1-1z"></path>
                        <path
                            d="m11 28.586-1.293-1.293a1 1 0 0 0 -1.414 0l-2 2 1.414 1.414 1.293-1.293 1.293 1.293a1 1 0 0 0 1.414 0l2-2-1.414-1.414z"></path>
                      </g>
                    </svg>
                  </p>
                </Link>
              </li>
              <li className="menu__item ">
                <Link to="dostavka" smooth={true} offset={-50} className="menu__link">
                  Доставка
                </Link>
              </li>
            </ul>
          </nav>
          <div className="header__contacts-block">
            <p className="header__contacts">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 22 22">
                <image id="phone-call"
                       href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAABkElEQVQ4jZ3Uz4tOURzH8ddIJDViMcyKhZRfGz8axUIzxNJGWdha2NhMsrBQivwJErtZsGbDjJnNNGEhKZYGRUPJj1GkJvpyrp7OmXvv8zzvzb33+/med/eee84ZWDi0Rwd7cQE/cAmv9cnKjmEHcRfr0/NxjOJFP+oV6boP0x3SYCjVthajuhQP4x5WFek/+WNsL5IWQnwAGxvaNuAO1hZJAyFeXR//ZwfWFNUGQvwBv+pb/jKL70W1gRC/xNealiVcxAn8LNIGqjeeq2m5gqv4UiQtVMvt3DJtv/GmqPYofotbWTaAo+natzi4jIUsP4WdxagexfHW54sOHmQ7MmcdRjBYJw4mcD2rDSf5UKFkE57hEZ5jW504GMdkVouz5CkOZ3N+DVvS/ea0/WMzyY/NithlT7Arq8e6nsJMOmJPFiN5jyN1Ymle72N/kbTzbbmpqPiMMTwsknYGm8TBIo6lH7pUpA20iYMQnsXp9BXd8LEbccVt7MbNlr75OON7EQfvcAbxx2/gU5a/ihWB+T/QQkL5i6WdbQAAAABJRU5ErkJggg=="></image>
              </svg>
              <a href="tel:+79216320626">+7 (921) 632 0 626</a></p>
            <p className="header__addres">​д. Малое Верево, <br/> Киевское шоссе, 2г </p></div>
          <div className="raiting">
            <iframe src="https://yandex.ru/sprav/widget/rating-badge/200691836365?type=rating" width="150" height="50"
                    frameBorder="0"></iframe>
          </div>
          {/*<div className='baner-warning' >*/}
          {/*  <div className='baner-wrapper'>*/}
          {/*    <p>Ведутся технические работы.</p>*/}
          {/*    <p>Цены могут отличаться от заявленных на сайте. Уточняйте у оператора кафе. </p>*/}
          {/*    <p>В ближайшие дни устраним.</p>*/}
          {/*    <p>С уважением, Фингер Фуд.</p>*/}
          {/*    <button onClick={closeBaner}>Принять</button>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
      <div className="baner-text container">
        <h1 className="h1-hidden">Пицца Гатчина</h1>
        <p className="baner-text__description">Хрустящая пицца на дровах,<br/>Роллы и сочные чебуреки</p>
        <p className="baner-text__title">Принимаем заказы: <br/> <span>по Гатчине и Гатчинскому району</span></p>
        <p className="baner-text__description">Ежедневно с 11.00 до 20.45</p>
      </div>
    </header>
  );
}

export default Header;
