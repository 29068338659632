export default {
    name: "Темпура роллы",
    anchor: "tempura",
    items: [
        {
            name: "Биг темпура",
            img: "/images/menu/0V4A8671 .jpg",
            alt: "",
            desc: "рис, нори, сыр, угорь, спайси-соус, огурец, масаго, кляр, сухари панировочные, креветка",
            price: 540,
            size: null,
        },
        {
            name: "Темпура эби",
            img: "/images/menu/0V4A8629 .jpg",
            alt: "",
            desc: "рис, нори, сыр, креветка, огурец, масаго, кляр, панировочные сухари",
            price: 549,
            size: null,
        },
        {
            name: "Темпура с тунцом",
            img: "/images/menu/0V4A8650 .jpg",
            alt: "",
            desc: "рис, нори, сыр, огурец, тунец, панировочные сухари, кляр",
            price: 529,
            size: null,
        },
        {
            name: "Темпура с лососем",
            img: "/images/menu/0V4A8657 .jpg",
            alt: "",
            desc: "рис, нори, сыр, лосось, огурец, панировочные сухари, кляр",
            price: 589,
            size: null,
        },    {
            name: "Темпура с угрем",
            img: "/images/menu/0V4A8807 .jpg",
            alt: "",
            desc: "рис, нори, сыр, огурец, угорь, унаги-соус, панировочные сухари, кляр\n",
            price: 679,
            size: null,
        },    {
            name: "Цезарь темпура",
            img: "/images/menu/Рол цезарь темпура_.JPG",
            alt: "",
            desc: "рис, нори, курица, салат айсберг, томаты, цезарь-соус, кляр, панировочные сухари\n",
            price: 470,
            size: null,
        },
        {
            name: "Сахара-ролл",
            img: "/images/menu/0V4A8633 .jpg",
            alt: "",
            desc: "рис, нори, угорь, масаго, крабовый микс, панировочные сухари, кляр",
            price: 559,
            size: null,
        },
        {
            name: "Якудза",
            img: "/images/menu/0V4A8636 .jpg",
            alt: "",
            desc: "рис, нори, сыр виолла, лук зеленый, лосось, кляр, панировочные сухари",
            price: 519,
            size: null,
        },
        {
            name: "Сэндвич",
            img: "/images/menu/0V4A8813 .jpg",
            alt: "",
            desc: "рис, нори, барбекю-соус, курица, лук зеленый, кляр, панировка\n",
            price: 500,
            size: null,
        },
    ],
  };
  