export default {
  name: "Роллы и суши",
  anchor: "rolli",
  items: [
    {
      name: "Аляска",
      img: "/images/menu/0V4A8608 .jpg",
      alt: "",
      desc: "рис, нори, сыр, лосось, огурец",
      price: 499,
      size: null,
    },

    {
      name: "Филадельфия",
      img: "/images/menu/0V4A1233 .jpg",
      alt: "",
      desc: "рис, нори, сыр, огурец, лосось",
      price: 560,
      size: null,
    },
    {
      name: "Канада",
      img: "/images/menu/0V4A1219 .jpg",
      alt: "",
      desc: "рис, нори, сыр, огурец, лосось, угорь, унаги-соус, кунжут",
      price: 669,
      size: null,
    },
    {
      name: "Маки с тунцом",
      img: "/images/menu/0V4A8512 .jpg",
      alt: "",
      desc: "рис, нори, тунец",
      price: 299,
      size: null,
    },
    {
      name: "Маки с лососем",
      img: "/images/menu/0V4A8516 .jpg",
      alt: "",
      desc: "рис, нори, лосось",
      price: 349,
      size: null,
    },
    {
      name: "Маки с угрем",
      img: "/images/menu/0V4A8537 .jpg",
      alt: "",
      desc: "угорь, рис, нори, соус унаги, кунжут",
      price: 349,
      size: null,
    },
    {
      name: "Маки с авокадо",
      img: "/images/menu/0V4A8591 .jpg",
      alt: "",
      desc: "рис, нори, авокадо",
      price: 200,
      size: null,
    },
    {
      name: "Маки с огурцом",
      img: "/images/menu/0V4A8587 .jpg",
      alt: "",
      desc: "рис, нори, огурец",
      price: 200,
      size: null,
    },
    {
      name: "Эби темпура с креветкой",
      img: "/images/menu/0V4A8543 .jpg",
      alt: "",
      desc: "рис, нори, сыр, креветка темпура, масаго, кунжут",
      price: 590,
      size: null,
    },
    {
      name: "Дракон",
      img: "/images/menu/IMG_1221.JPG",
      alt: "",
      desc: "рис, нори, угорь, снежный краб, унаги-соус, кунжут, масаго, огурец",
      price: 649,
      size: null,
    },
    {
      name: "Калифорния с лососем",
      img: "/images/menu/0V4A8573 .jpg",
      alt: "",
      desc: "рис, нори, сыр, лосось, масаго, огурец",
      price: 519,
      size: null,
    },
    {
      name: "Калифорния",
      img: "/images/menu/0V4A8574 .jpg",
      alt: "",
      desc: "рис, нори, снежный краб, майонез, огурец, масаго",
      price: 489,
      size: null,
    },
    {
      name: "Лосось спайси",
      img: "/images/menu/0V4A8579 .jpg",
      alt: "",
      desc: "рис, нори, огурец, лосось, спайси-соус",
      price: 499,
      size: null,
    },
    {
      name: "Сяке темпура",
      img: "/images/menu/0V4A8597 .jpg",
      alt: "",
      desc: "рис, нори, лосось, креветка темпура, спайси-соус, огурец",
      price: 660,
      size: null,
    },
    {
      name: "Фила люкс",
      img: "/images/menu/0V4A8617 .jpg",
      alt: "",
      desc: "рис, нори, сыр, лосось, угорь, огурец, унаги-соус, кунжут",
      price: 699,
      size: null,
    },
    {
      name: "Эби лайт",
      img: "/images/menu/0V4A8677 .jpg",
      alt: "",
      desc: "рис, нори, сыр, огурец, креветка",
      price: 520,
      size: null,
    },


    {
      name: "Филадельфия премиум",
      img: "/images/menu/0V4A8839 .jpg",
      alt: "",
      desc: "рис, нори, сыр, огурец, лосось, икра лососевая",
      price: 795,
      size: null,
    },
    {
      name: "Филадельфия опаленная",
      img: "/images/menu/0V4A8746 .jpg",
      alt: "",
      desc: "рис, нори, сыр, огурец, лосось",
      price: 590,
      size: null,
    },
    {
      name: "Киото-ролл",
      img: "/images/menu/f22ebf.jpeg",
      alt: "",
      desc: "рис, нори, сыр, угорь, лосось, огурец, масаго",
      price: 620,
      size: null,
    },
    {
      name: "Радуга",
      img: "/images/menu/0V4A8776 .jpg",
      alt: "",
      desc: "рис, нори, крабовый микс, огурец, угорь, лосось, креветка, авокадо, тунец\n",
      price: 660,
      size: null,
    },
    {
      name: "Спайси-тунец",
      img: "/images/menu/0V4A8786 .jpg",
      alt: "",
      desc: "рис, нори, сыр, угорь, чука, тунец, спайси-соус",
      price: 680,
      size: null,
    },

    {
      name: "Меркурий",
      img: "/images/menu/0V4A8738 .jpg",
      alt: "",
      desc: "нори, рис, сыр, угорь, чука, унаги-соус, кунжут",
      price: 490,
      size: null,
    },

    {
      name: "Тигровый ролл",
      img: "/images/menu/0V4A8801 .jpg",
      alt: "",
      desc: "рис, нори, сыр, креветка, огурец, авокадо, лосось, майонез, масаго",
      price: 645,
      size: null,
    },
    {
      name: "Бренд-ролл",
      img: "/images/menu/0V4A8795 .jpg",
      alt: "",
      desc: "рис, нори, сыр, жареный лосось, масаго, стружка из тунца\n",
      price: 609,
      size: null,
    },
    {
      name: "Окинава",
      img: "/images/menu/0V4A8729 .jpg",
      alt: "",
      desc: "рис, нори, сыр, масаго, огурец, спайси-соус, креветка темпура\n",
      price: 540,
      size: null,
    },

    {
      name: "Веган-ролл",
      img: "/images/menu/Веган ролл.jpg",
      alt: "",
      desc: "рис, нори, огурец, авакадо, перец болгарский, томаты\n",
      price: 320,
      size: null,
    },
    {
      name: "Цезарь-ролл",
      img: "/images/menu/Рол цезарь_.JPG",
      alt: "",
      desc: "рис, нори, сыр, курица, салат айсберг, томаты, цезарь-соус\n",
      price: 450,
      size: null,
    },
    {
      name: "Унаги лайт",
      img: "/images/menu/Рол унаги лайт_.JPG",
      alt: "",
      desc: "рис, нори, сыр, огурец, угорь, унаги-соус, кунжут\n",
      price: 560,
      size: null,
    },
    {
      name: "Суши креветка",
      img: "/images/menu/945972.jpeg",
      alt: "",
      desc: "рис, креветка",
      price: 399,
      size: null,
    },
    {
      name: "Суши угорь",
      img: "/images/menu/5eb36d.jpeg",
      alt: "",
      desc: "рис, нори, угорь, унаги-соус, кунжут",
      price: 499,
      size: null,
    },
    {
      name: "Суши лосось",
      img: "/images/menu/60464c6d367.jpeg",
      alt: "",
      desc: "рис, лосось",
      price: 499,
      size: null,
    },
  ],
};
